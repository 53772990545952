import React from "react";
import profileImage from '../../images/samir.jpg';
import resumeLink from '../../images/samir_cv1.pdf';

export default function About() {
    return (
        <section id="about">
            <div className="row">

                <div className="twelve columns main-col">
                    <h2 ><span className="page_title">About Me</span></h2>
                    <div className="row">
                        <h2>I am Samir Karmacharya, a freelance web developer based in Nepal, but working globally with different Startups and Agencies.</h2>
                        <p> Using the latest trends and technologies, I can create a new high-quality website or redesign existing websites for an individual or an organization.</p>
                        <p>  Passionate Programmer with more than 9 years of working experience.Specialized in web Programmer, WordPress, E-Commerce, optimization, Security.
                            Worked on more than 200 websites for national and international  Agency from Australia.</p>
                    </div>
                    <div className="row">
                        <div className=" columns">
                            <img className="profile-pic" src={profileImage} alt="Tim Baker Profile Pic" />
                        </div>
                        <div className="columns contact-details">
                            <h2>Contact Details</h2>
                            <p className="address">
                                <span>Samir Karmacharya</span><br />
                                <span>Chochhen Bhaktapur-6<br />
                                </span>
                                <span>+977 9848936432</span><br />
                                <span>samirkarmacharya68@gmail.com</span>
                            </p>
                        </div>
                        <div className=" columns download">
                            <p>
                                <a href={resumeLink} className="button"><i className="fa fa-download"></i>Download Resume</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
import React from "react";
import development from '../../images/development.jpg';
import seo from '../../images/seo.jpg';
import maintenance from '../../images/maintenance.jpg';
export default function Resume() {
    return (
        <section id="resume">

            <div className="row education">
                <div className="twelve columns header-col">
                    <h2><span className="page_title">What I Do</span></h2>
                    <h4>I create beautiful websites that
                        stand out on major search engines</h4>
                </div>

                <div className="twelve columns main-col">
                    <div className="row item">
                        <div className="twelve columns">
                            <div className="four whatido_item columns">
                                <div className="whatido_image">
                                    <img src={development} alt="website development" />
                                </div>
                                <div className="whatido_content">
                                    <div className="whatido_title">
                                        <h2>Website Development</h2>
                                    </div>
                                    <div className="whatido_description">
                                        <p>The most important factor to have a presence online is to have a website. I create stunning responsive websites that generate leads using the latest and modern edge technology. I will be alongside you since the initial planning phase of a website to the creation and post-launch for support and maintenance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="four  whatido_item columns">
                                <div className="whatido_image">
                                    <img src={seo} alt="website seo" />
                                </div>
                                <div className="whatido_content">
                                    <div className="whatido_title">
                                        <h2>Search Engine Optimization</h2>
                                    </div>
                                    <div className="whatido_description">
                                        <p>There is no use of a website if it does not rank on Google and other major search engines. I can help your website show up when people search for the services you provide. I also provide you audit report on what updates should you make on your website so that Google ranks your website.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="four  whatido_item columns">
                                <div className="whatido_image">
                                    <img src={maintenance} alt="Website Maintenance" />
                                </div>
                                <div className="whatido_content">
                                    <div className="whatido_title">
                                        <h2>Website Maintenance</h2>
                                    </div>
                                    <div className="whatido_description">
                                        <p>I provide regular website maintenance service. This service includes regular backup, WordPress updates, themes and plugin updates. It also includes security check, database optimization, regular website inspection. I can also help with the regular content updates.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="row work">

                <div className="twelve columns header-col">
                    <h2><span className="page_title">Work</span></h2>
                </div>

                <div class="twelve columns main-col work_section">
                    <div>
                        <h3>Whitehat Agency Pvt. Ltd</h3>
                        <p class="info">Senior Web Programmer <span>-</span> <em class="date"> Sep 2017- Jul 2022</em></p>
                        <ul>
                            <li>Convert provided design files to fully-fledged WordPress website.
                            </li>
                            <li>
                                Develop custom and Customize WordPress plugins when required.
                            </li>
                            <li>
                                Regularly maintain all the websites. Update the WordPress core, plugins, themes and do
                                necessary tasks for monthly maintenance.
                            </li>
                            <li>
                                Write semantic, quality code and also help the team members to do the same.
                            </li>
                            <li>
                                Communicate and discuss with the offshore Project Manager about the project requirement
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>Link Plus Offshore Pvt. Ltd</h3>
                        <p class="info">Web Programmer and Project Manager<span>
                            -</span>
                            <em class="date">Jun 2012-Aug 2017</em>
                        </p>
                        <ul>
                            <li>Convert provided design files to fully-fledged WordPress website.
                            </li>
                            <li> Develop custom and Customize WordPress plugins when required.
                            </li>
                            <li> Communicate and discuss with the offshore Project Manager about the project requirement
                                its scope, deadlines and updating them on the progress on the web projects
                            </li>
                            <li> Manage a team of WordPress developer, assign tasks, QA on the tasks done.
                            </li>
                            <li> Lead a team of WordPress developer, conduct regular meetings, training to upgrade the
                                team member’s skills.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



            {/* <div className="row skill">

                <div className="three columns header-col">
                    <h1><span>Skills</span></h1>
                </div>

                <div className="nine columns main-col">

                    <p>Here you can create a short write-up of your skills to show off to employers
                    </p>

                    <div className="bars">
                        <ul className="skills">
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>Git</em></li>
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>React</em></li>
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>WordPress</em></li>
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>Opencart</em></li>
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>HTML</em></li>
                            <li><span className="bar-expand git" style={{ width: "50%" }}></span><em>CSS</em></li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </section >
    )
}
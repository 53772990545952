import React from 'react';
function Header() {
    return (
        <header id="home">
            {/* <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                <ul id="nav" className="nav">
                    <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                    <li><a className="smoothscroll" href="#about">About</a></li>
                    <li><a className="smoothscroll" href="#resume">Resume</a></li>
                    <li><a className="smoothscroll" href="#portfolio">Works</a></li>
                    <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
                    <li><a className="smoothscroll" href="#contact">Contact</a></li>
                </ul>
            </nav> */}

            <div className="row banner">
                <div className="banner-text">
                    <h1 className="responsive-headline"> - Believe in quality work and long-run relationships</h1>

                    <hr />
                    <ul class="social">
                        <li>
                            <a href="https://www.facebook.com/samir.karmacharya.52"><i class="fa fa-facebook"></i></a>
                        </li>

                        <li>
                            <a href="https://www.linkedin.com/in/samirkarmacharya/"><i class="fa fa-linkedin"></i></a>
                        </li>
                        {/* <li>
                            <a href="http://instagram.com/tbaker_x"><i class="fa fa-instagram"></i></a>
                        </li> */}
                        <li>
                            <a href="https://github.com/karmacharya50"><i class="fa fa-github"></i></a>
                        </li>
                    </ul>
                </div>
            </div>

            <p className="scrolldown">
                <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
            </p>

        </header>
    )
}
export default Header;
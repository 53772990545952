
import './App.css';
import Header from './components/header/header';
import About from './components/pages/about';
import Resume from './components/pages/resume';
import Portfolio from './components/pages/portfolio';
import Footer from './components/footer/footer';


function App() {
  return (
    <div className="App">
      <Header className="App-header">

      </Header>
      <About></About>
      <Resume></Resume>
      <Portfolio></Portfolio>
      <Footer></Footer>
    </div>
  );
}

export default App;

import React from "react";
import orix from '../../images/orix.jpg';
import awake from '../../images/awake.jpg';
import getfish from '../../images/getfish.jpg';
import vetmap from '../../images/vetmap.jpg';
import vianapoli from '../../images/vianapoli.jpg';
export default function Portfolio() {
    return (
        <section id="portfolio">

            <div className="row">

                <div className="twelve columns collapsed">

                    <h2>Check Out Some of My Works.</h2>

                    <div id="portfolio-wrapper" className="">
                        <div className="three columns portfolio-item">
                            <div className="item-wrap">
                                <a href="https://www.orix.com.au/" title="Canadian Wanderlust">
                                    <img alt="Canadian Wanderlust" src={orix} />
                                    <div className="overlay">
                                        <div className="portfolio-item-meta">
                                            <h5>Orix</h5>

                                        </div>
                                    </div>
                                    <div className="link-icon"><i className="fa fa-link"></i>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="three columns portfolio-item">
                            <div className="item-wrap">
                                <a href="https://awakeacademy.com.au/" title="Canadian Wanderlust">
                                    <img alt="Canadian Wanderlust" src={awake} />
                                    <div className="overlay">
                                        <div className="portfolio-item-meta">
                                            <h5>Awake Academy</h5>

                                        </div>
                                    </div>
                                    <div className="link-icon"><i className="fa fa-link"></i>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="three columns portfolio-item">
                            <div className="item-wrap">
                                <a href="https://www.getfish.com.au/" title="Canadian Wanderlust">
                                    <img alt="Canadian Wanderlust" src={getfish} />
                                    <div className="overlay">
                                        <div className="portfolio-item-meta">
                                            <h5>GetFish</h5>
                                            <p>My Travel Blog for my post-university travels</p>
                                        </div>
                                    </div>
                                    <div className="link-icon"><i className="fa fa-link"></i>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="three columns portfolio-item">
                            <div className="item-wrap">
                                <a href="https://www.thevetmap.com/" title="Canadian Wanderlust">
                                    <img alt="Canadian Wanderlust" src={vetmap} />
                                    <div className="overlay">
                                        <div className="portfolio-item-meta">
                                            <h5>The Veterinary Map</h5>

                                        </div>
                                    </div>
                                    <div className="link-icon"><i className="fa fa-link"></i>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="three columns portfolio-item">
                            <div className="item-wrap">
                                <a href="https://vianapoli.com.au/" title="Canadian Wanderlust">
                                    <img alt="Canadian Wanderlust" src={vianapoli} />
                                    <div className="overlay">
                                        <div className="portfolio-item-meta">
                                            <h5>ViaNapoli</h5>
                                        </div>
                                    </div>
                                    <div className="link-icon"><i className="fa fa-link"></i>
                                    </div>
                                </a>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}